<template>
  <v-list class="py-0" :tile="false" nav>
    <notification-item
      v-for="(n, i) in notifications.slice(0, 3)"
      :key="`item-${i}`"
      :item="n"
      :dash="dash"
    />
    <v-list-item
      class="mt-2"
      style="
        display: flex;
        justify-content: center;
        min-height: 10px;
        align-item: end;
      "
    >
      <v-row>
        <v-col class="pa-0">
          <v-btn
            class="mt-1"
            text
            small
            left
            @click="$router.push({ name: 'notifications' })"
          >
            {{ $t("see_more") }}
            <v-span class="pl-1" v-if="notifications.length > 3">
              +{{ notifications.length - 3 }}
            </v-span>
          </v-btn></v-col
        >
        <v-col
          v-if="notifications.length > 0"
          style="display: flex; justify-content: flex-end"
          class="pa-0"
        >
          <v-btn class="mt-1" text small right @click="allMark()">
            {{ $t("notifications.read") }}
          </v-btn></v-col
        >
      </v-row>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NofificationsList",
  props: ["dash"],
  computed: {
    ...mapState("auth", ["notifications"]),
  },
  methods: {
    ...mapActions("auth", ["markAllNotification"]),
    allMark() {
      this.$confirm(this.$t("markAllNotification"), "", "").then(() => {
        this.markAllNotification();
        this.$store.dispatch("auth/getNotifications");
      });
    },
  },
  components: {
    NotificationItem: () => import("@/components/notifications/Item"),
  },
};
</script>

